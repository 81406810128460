import { CataloguesService } from '../services/LandingV4'

const catalogos = new CataloguesService()
const phoneValidate = async (creditApplicationId, telefono) => {
    try {
        const response = await catalogos.postPhoneValidate(creditApplicationId, telefono)

        // console.log(response)
        if (response.data.isValid) {
            return true
        } else {
            return false
        }
    } catch (error) {
        console.log(error)
    }
}

export default phoneValidate